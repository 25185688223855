import React, { useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import axios from 'axios';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Editor } from '@tinymce/tinymce-react';
import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    CircularProgress,
    TextareaAutosize,
    Checkbox
} from '@material-ui/core';

import { wordpressBaseUrl } from 'settings';

export default function NotificationModal(props) {
    const { open, onClose, editNotification, setEditNotification, afterSave, type } = props;
    const [message, setMessage] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [isUrgent, setIsUrgent] = React.useState(false);
    const [errors, setErrors] = React.useState();
    
    useEffect(()=>{
        setErrors();
        if (!editNotification) {
            setMessage();
            setIsUrgent(false);
        }
    }, [open]);

    useEffect(()=> {
        if (editNotification) {
            // console.log('editNotification', editNotification)
            setMessage(editNotification.message);
            if (parseInt(editNotification.is_urgent)) setIsUrgent(1);
            // setDescription(editNotification.description);
            // setYear(editNotification.year);
            // setMonth(editNotification.month);
        } else {
            setMessage();
        }
    }, [editNotification])
    
    function isEmptyObject(obj) {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
      }

    const validateInput = () => {
        let allerrors = {};
        if (!message) {
            allerrors.message = "Message field is required!";
        }  
        // if (!description) {
        //     allerrors.description = "Description field is required!";
        // }  
        // if (!year) {
        //     allerrors.year = "Year field is required!";
        // }
        // if (!month) {
        //     allerrors.month = "Month field is required!";
        // }
        if (isEmptyObject(allerrors)){
            return false;
        }
        setErrors(allerrors);
        return allerrors;
    }
    
    const submit = async () => {
        setLoading(true)
        setErrors(null);
        let allerrors = validateInput();
        if (allerrors){
            setLoading(false);
            return;
        }
        const url = `${wordpressBaseUrl}/wp-json/hh-api/save-notification`;
        let input = {
            message,
            type : type,
            is_urgent: isUrgent
        };
        if(editNotification && editNotification.id) input.id = editNotification.id;
        const response = await axios.post(url, input);

        console.log("update team response", response.data);
        if (response.data.success) {
            afterSave();
            setMessage();
            setIsUrgent(false);
            setLoading(false)
        }
    }

    const handleClose = () => {
        setMessage();
        setIsUrgent(false);
        onClose();
    }

    function convertToTitleCase(str) {
        return str
            .split('-') // Split the string by hyphen '-'
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
            .join(' '); // Join the words with a space
    }

    const handleEditorChange = (content, editor) => {
        // console.log('content', content.length)
        setMessage(content);
    }

    return (
        open
            ? (<div>
                    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title2" maxWidth={'md'} fullWidth={true} disableEnforceFocus={true}>
                        <DialogTitle id="form-dialog-title2">{editNotification ? "Edit" : "Add"} {convertToTitleCase(type)}</DialogTitle>
                        <DialogContent>
                        <Editor
                            tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce.min.js'}
                            value={message}
                            init={{
                                force_br_newlines: true,
                                force_p_newlines: false,
                                forced_root_block: '',
                                height: 500,
                                menubar: false,
                                plugins: [
                                    'advlist lists link charmap preview anchor', 'image',
                                    'searchreplace visualblocks',
                                    'insertdatetime table paste link'
                                ],
                                toolbar: 'undo redo | formatselect | ' +
                                    'bold italic backcolor | alignleft aligncenter |' +
                                    'alignright alignjustify | link | bullist numlist outdent indent table tablecellvalign | ' +
                                    'removeformat | help | image ',
                                content_style: 'body { font-size:14px }',
                                default_link_target: '_blank',
                                // images_file_types: 'jpg,svg,webp,png,jpeg',
                                // file_picker_types: 'media',
                                // images_upload_handler: uploadImage,
                                relative_urls: false, // Disable relative URLs
                                remove_script_host: false, // Keep the full host in URLs
                                convert_urls: false, // Prevent TinyMCE from modifying links
                            }}
                            onEditorChange={handleEditorChange}
                        />
                            {/* <TextareaAutosize
                                 id="outlined-textarea"
                                 label={ "Enter Comment"}
                                 fullWidth
                                 minRows={5}
                                 style={{width: "100%", fontFamily: "'Poppins', sans-serif"}}
                                 variant="outlined"
                                 // error={ isError}
                                 value={message}
                                 onChange={(e) => {
                                       setMessage(e.target.value);
                                       setErrors();
                                       // setIsError(false);
                                 }}
                              /> */}
                            <br /><br />
                            <div >
                                    <Checkbox style={{marginLeft: "-10px"}} checked={isUrgent}
                                                onChange={(e)=>setIsUrgent(e.target.checked ? 1 : 0)}
                                                color="primary"
                                            /> Urgent
                                </div>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={submit} color="primary">
                            {loading ? <CircularProgress size={24} style={{ margin:"auto" }}/> : "Submit"}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>)
            : null)
}

NotificationModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func
};